<template>
    <div class="form-container">
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(askContact)" v-if="!loading">
                <div class="form-row">
                    <div class="form-col">
                        <ValidationProvider  class="input-container"  rules="required|max:100" v-slot="{ errors }">
                            <input type="text" :class="{ 'input-error' : errors[0]}" placeholder="First Name*" v-model="firstname" />
                            <span>{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                    <div class="form-col">
                        <ValidationProvider  class="input-container"  rules="required|max:100" v-slot="{ errors }">
                            <input type="text" :class="{ 'input-error' : errors[0]}"  placeholder="Last Name*" v-model="lastname" />
                            <span>{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-col">
                         <ValidationProvider  class="input-container"  rules="required|max:100" v-slot="{ errors }">
                            <input type="text" placeholder="Company*" :class="{ 'input-error' : errors[0]}" v-model="company" />
                            <span>{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                    <div class="form-col">
                         <ValidationProvider  class="input-container"  rules="max:100" v-slot="{ errors }">
                            <input type="text" placeholder="Job" :class="{ 'input-error' : errors[0]}" v-model="job" />
                            <span>{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
        
                </div>   
                <div class="form-row">
                    <div class="form-col">
                         <ValidationProvider  class="input-container"  rules="max:100" v-slot="{ errors }">
                            <input type="text" placeholder="City" :class="{ 'input-error' : errors[0]}" v-model="city" />
                            <span>{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                    <div class="form-col">
                         <ValidationProvider  class="input-container"  rules="max:100" v-slot="{ errors }">
                            <input type="text" placeholder="Country" :class="{ 'input-error' : errors[0]}" v-model="country" />
                            <span>{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
        
                </div> 
                <div class="form-row">
                    <div class="form-col">
                        <ValidationProvider  class="input-container"  rules="required|max:100|email" v-slot="{ errors }">
                            <input type="text" :class="{ 'input-error' : errors[0]}"  placeholder="Email*" v-model="email" />
                            <span>{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                    <div class="form-col">
                        <ValidationProvider  class="input-container"  rules="max:100" v-slot="{ errors }">
                            <input type="text" :class="{ 'input-error' : errors[0]}" placeholder="Phone" v-model="phone" />
                            <span>{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div> 
                <div class="form-row">
                    <p class="text">Select one or several roundtable(s) in which you want to be registered:</p>
                </div>
                <div class="form-row row-checkbox">
                    <div class="checkbox-container">
                        <input type="checkbox" value="Digital Transformation: Keeping your digital transformation project on the right track and securing your ROI" v-model="checkedNames">
                        <label><strong>Digital Transformation:</strong> Keeping your digital transformation project on the right track and securing your ROI</label>
                    </div>
                    <div class="checkbox-container">
                        <input type="checkbox" value="Industry 4.0: Make your digital transformation a success in optimizing your production efficiency" v-model="checkedNames">
                        <label><strong>Industry 4.0:</strong> Make your digital transformation a success in optimizing your production efficiency</label>
                    </div>
                    <div class="checkbox-container">
                        <input type="checkbox" value="Facilities 4.0: How can an IoT digital strategy accelerate your transformation to Factory 4.0 to better manage performance, quality of the working environment, safety and security, or decreasing the carbon footprint of your business" v-model="checkedNames">
                        <label><strong>Facilities 4.0:</strong> How can an IoT digital strategy accelerate your transformation to Factory 4.0 to better manage performance, quality of the working environment, safety and security, or decreasing the carbon footprint of your business</label>
                    </div>
                    <div class="checkbox-container">
                        <input type="checkbox" value="Service 4.0: What is the current state of digital transformation of inspection and maintenance for complex assets, what are the challenges and future use cases" v-model="checkedNames">
                        <label><strong>Service 4.0:</strong> What is the current state of digital transformation of inspection and maintenance for complex assets, what are the challenges and future use cases</label>
                    </div>
                    <div class="checkbox-container">
                        <input type="checkbox" value="Industry 4.0: Integrating MES functions with an IIoT platform can bring new opportunities for manufacturers" v-model="checkedNames">
                        <label><strong>Industry 4.0:</strong> Integrating MES functions with an IIoT platform can bring new opportunities for manufacturers</label>
                    </div>
                    <div class="checkbox-container">
                        <input type="checkbox" value="Legacy Replacement: Manage the obsolescence of your legacy engineering (PLM) systems" v-model="checkedNames">
                        <label><strong>Legacy Replacement:</strong> Manage the obsolescence of your legacy engineering (PLM) systems</label>
                    </div>
                    <div class="checkbox-container">
                        <input type="checkbox" value="Maintenance 4.0: How to optimize your maintenance solutions with an IIoT platform approach, while maintaining scalability to meet tomorrow’s challenges and benefits" v-model="checkedNames">
                        <label><strong>Maintenance 4.0:</strong> How to optimize your maintenance solutions with an IIoT platform approach, while maintaining scalability to meet tomorrow’s challenges and benefits</label>
                    </div>
                    <div class="checkbox-container">
                        <input type="checkbox" value="Data Fabric: Improve your digital continuity without adding additional entropy or complexity" v-model="checkedNames">
                        <label><strong>Data Fabric:</strong> Improve your digital continuity without adding additional entropy or complexity</label>
                    </div>
                    <div class="checkbox-container">
                        <input type="checkbox" value="Immersive Rendering: Boosting operator effectiveness by leveraging XR experiences" v-model="checkedNames">
                        <label><strong>Immersive Rendering:</strong> Boosting operator effectiveness by leveraging XR experiences</label>
                    </div>
                </div>
                <div class="form-row form-submit">
                    <input type="submit" class="btn-form" value="Send" />
                </div>
                <div class="notif success" v-if="succes">
                    <p>Your message has been sent successfully.<br />You will be contacted by our team shortly.</p>
                </div>
                <div v-if="error" class="notif error">
                    <p>{{error}}</p>
                </div>
            </form>
            <div v-else class="loader-container">
                <Loader></Loader>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
import Loader from './Loader'
import { ValidationProvider,  ValidationObserver } from 'vee-validate'
import axios from 'axios'

export default {
    name : "RTForm",
    components : {
        ValidationProvider,
        ValidationObserver,
        Loader
    },
    data() {
        return {
            firstname : null,
            lastname : null,
            company : null,
            phone : null,
            email : null,
            city : null,
            country : null,
            job : null,
            error : null,
            succes : false,
            loading: false,
            checkedNames : []
        }
    },
    methods : {
        askContact() {
           
            this.error = null
            this.succes = false

            console.log('length => ' + this.checkedNames.length)

            if(this.checkedNames.length < 1) {
                this.error = "Please select at least one roundtable";
            }else {
                 this.loading = true
                let formData = new FormData();

                formData.append('firstname', this.firstname)
                formData.append('lastname', this.lastname)
                formData.append('email', this.email)
                formData.append('company', this.company)
                formData.append('from', this.$route.name)
                formData.append('checkedNames', JSON.stringify(this.checkedNames))
                if(this.phone) {
                    formData.append('phone', this.phone)
                }            
                if(this.company) {
                    formData.append('city', this.city)
                }
                if(this.country) {
                    formData.append('country', this.country)
                }
                if(this.job) {
                    formData.append('job', this.job)
                }

                let config = {
                    validateStatus: () => true,
                };
                let vm = this

                axios
                    .post('https://percallnext.com/mailer/roundtables.php', formData, config)
                    .then(function (res) {
                        vm.loading = false
                        if (res.status == 400) {
                            vm.error = 'Please fill out all the mandatory fields.'
                        }else if(res.status == 500) {
                            vm.error = 'A server error has occured. Please try again later.'
                        
                        }else if(res.status == 200) {
                                vm.succes = true
                                vm.firstname = null
                                vm.lastname = null
                                vm.company = null
                                vm.phone = null
                                vm.email = null
                                vm.city = null
                                vm.country = null
                                vm.job = null
                                vm.checkedNames = []
                            }else {
                                vm.error = 'An error has occured. Please try again.'
                            }
                        }
                    ) 
            }    
        } 
    }
}
</script>

<style lang="scss" scoped>
    .form-container {
        width: 1000px;
        max-width: 90%;
        margin:0 auto;
    }

    .form-container span:first-of-type {
        display: flex;
        flex-direction: column; 
        width: 100%;
    }
    
    form {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .form-row {
        width:100%;
        display: flex;
        justify-content: center;
        margin:10px 0;
    }

    .form-col {
        width:50%;
        display: flex;
        justify-content: center;
    }

    input, textarea {
        background:#fff;
        color:$text-color;
        padding:12px 20px;
        font-size:15px;
        font-weight:400;
        border-radius:10px;
        border: 2px solid #fff;
        // width:calc(100% - 40px);
        margin:0 10px;
        font-family: $font;
        // -webkit-box-shadow:inset 0px 5px 5px 1px $text-color;
        // -moz-box-shadow:inset 0px 5px 10px 1px $text-color;
        // box-shadow:inset 0px 5px 10px 1px $text-color;
    }

    input:focus, textarea:focus {
        outline: none;
        border:2px solid $main-color;
    }

    input::placeholder, textarea::placeholder {
        color:$text-color;
        font-family: $font;
    }

    .input-container {
        flex-direction: column;
    }

    .input-container span {
        color:$red;
        font-size:12px;
        margin-left: 30px;
    }

    input.input-error, textarea.input-error {
        border-color:$red;
    }

    input.input-error::placeholder, textarea.input-error::placeholder {
        color:$red;
    }

    .btn-form {
        width:200px;
        height:60px;
        border:1px solid $main-color;
        border-radius: 10px;
        background-color: $main-color;
        color:#fff;
        cursor: pointer;
        transition: 0.5s;
        font-size:20px;
        font-weight: 400;
        letter-spacing: 1px;
    }

    .btn-form:hover {
        background-color: #fff;
        color:$main-color;
    }

    .loader-container {
        display: flex;
        justify-content: center;
        height: 300px;
        align-items: center;
    }

    .notif {
        padding:10px 20px;
        border-radius:10px;
    }

    .notif.success {
        border:1px solid $succes;
    }

    .notif.success p {
        color:$succes;
    }

    .notif.error {
        border:1px solid $red;
    }

    .notif.error p {
        color:$red;
    }

    .notif p {
        text-align: center;
    }

    .row-checkbox {
        flex-direction: column;
    }

    .checkbox-container {
        margin:5px 0;
        display: flex;
        align-items:flex-start;
    }

    .checkbox-container input {
        appearance: none;
        width:15px;
        min-width: 15px;
        height:15px;
        border-radius:2px;
        background: #fff;
        padding:0;
        border:1px solid $text-color;
        margin-right: 10px;
    }

    .checkbox-container label {
        color:$text-color;
        font-family: $font;
    }

    .checkbox-container input:checked {
        background: $main-color;
    }

    p.text {
        font-weight:700;
        color:$text-color;
        text-align: left;
        width: 98%;
        margin-bottom:-10px;
    }

    @media screen and (max-width: 768px) {
        .form-col {
            width:100%;            
        }

        .form-row:not(.form-submit) {
            flex-direction: column;
            margin: 0;
        }

        .input-container {
            margin:10px 0;
        }

        .btn-form {
            width: 150px;
            height: 50px;
            font-size:18px;
        }

        p.text  {
            margin-bottom: 0;
        }
    }


</style>