<template>
    <div class="main-container">
        <!-- SECTION TITLE -->
        <section class="section-title">
            <div class="title-container">
                <div class="col-img">
                    <img src="./../assets/round-tables.png" alt="banner roundtables" />
                </div>
                <div class="col-title">
                    <h1>Virtual Roundtables </h1>
                    <p>Attend our different roundtables about Industry 4.0, Maintenance 4.0, Facilities 4.0, Service 4.0, Digital Transformation, Immersive Rendering, Data Fabric & Legacy Replacement.<br />Registration is free! </p>
                     <!-- <a href="https://percallnext.my-virtual-convention.com/register" target="_blank" class="actions-btn sponsors-btn margin-btn">Register for free</a> -->
                </div>
            </div>
        </section>
        <section class="table-container">

            <!-- ROW HEADER -->
            <div class="row row-header">
                <div class="table-theme"> Theme </div>
                <div class="table-title">Title</div>
                <div class="table-detail">Details</div>
                <div class="table-speaker">Dates</div>
            </div>

            
            <!-- ROW 1 -->
            <div class="row">
                <div class="table-theme">
                    <div class="table-number">6</div>
                    <h2>DIGITAL TRANSFORMATION</h2>
                    <h3>HOW TO START & SECURE <br />YOUR PROJECT?</h3>
                </div>
                <div class="table-title">
                    <p>
                        <span>Digital Transformation</span>
                        Keeping your digital transformation project on the right track and securing your ROI
                    </p>
                </div>
                <div class="table-detail">
                    <p>
                        According to Forrester survey on December 2020, 12% of those surveyed report having achieved digital transformation at enterprise scale, and only a select few succeeded to capture value and exceed the expected ROI. What are some ways of avoiding this PoC Purgatory trap?<br />
                        Digital Transformation is ready to be deployed on complete business cases, with a step by step business value methodology and industrial scalalable solutions.<br />
                        How can a value-based approach and the use of IIoT-based solutions make your digital transformation a reality and increase your ROI?
                    </p>
                </div>
                <div class="table-speaker">
                    <p class="table-date">March 22</p>
                    <p class="table-time">11h00-12h00 CET</p>
                    <div class="table-btn">
                        <div class="btn" @click="goRT()">Participate in this roundtable</div>
                    </div>
                </div>
            </div>
            <div class="row-sep"></div>

            
            <!-- ROW 4 -->
            <div class="row">
                <div class="table-theme">
                    <div class="table-number">5</div>
                    <h2>SERVICES</h2>
                    <h3>SUPERVISION <br />& INSPECTION</h3>
                </div>
                <div class="table-title">
                    <p>
                        <span>Service 4.0 </span>
                        What is the current state of digital transformation of inspection and maintenance for complex assets, what are the challenges and future use cases
                    </p>
                </div>
                <div class="table-detail">
                    <p>
                        Gartner says in July, 2021: “Oil and gas companies are intensifying the scale and scope of digital innovations to drive rapid change while maintaining efficiency”.  <br />
                        Faced with the daily challenges of maintaining an FPSO, a wind farm, a mining site, a nuclear power plant, and other large capital projects, the use of drones, smart sensors and robots is multiplying. What is the state of the leveraging of these technologies in the real world? What are some of the possible steps of digital transformation that remain to be taken? 
                    </p>
                </div>
               <div class="table-speaker">
                    <p class="table-date">March 22</p>
                    <p class="table-time">14h00-15h00 CET</p>
                    <div class="table-btn">
                        <div class="btn" @click="goRT()">Participate in this roundtable</div>
                    </div>
                </div>
            </div>
            <div class="row-sep"></div>
            
            <!-- ROW 2 -->
            <div class="row">
                <div class="table-theme">
                    <div class="table-number">1</div>
                    <h2>production</h2>
                    <h3>MONITORING,<br />EXECUTION<br />& SUPERVISION</h3>
                </div>
                <div class="table-title">
                    <p>
                        <span>Industry 4.0 </span>
                        Make your digital transformation a success in optimizing your production efficiency
                    </p>
                </div>
                <div class="table-detail">
                    <p>
                        With the explosion in the amount of captured data via IOT and IIOT Technologies, a critical question is knowing how to best leverage this information to drive your digital transformation.<br />
                        But given data complexity and volume, this is not a simple turnkey task.<br />
                        How can an approach built around an IOT platform help you to identify the main production difficulties and bottlenecks, investigate the specific issues, and elaborate concrete solutions (downtime analysis, workforce efficiency, and more)?
                    </p>
                </div>
               <div class="table-speaker">
                    <p class="table-date">March 22</p>
                    <p class="table-time">14h00-15h00 CET</p>
                    <div class="table-btn">
                        <div class="btn" @click="goRT()">Participate in this roundtable</div>
                    </div>
                </div>
            </div>
            <div class="row-sep"></div>

            <!-- ROW 3 -->
            <div class="row">
                <div class="table-theme">
                    <div class="table-number">4</div>
                    <h2>FACILITIES</h2>
                    <h3>EQUIPMENTS<br />& ENVIRONMENT</h3>
                </div>
                <div class="table-title">
                    <p>
                        <span>Facilities 4.0 </span>
                        How can an IoT digital strategy accelerate your transformation to Factory 4.0 to better manage performance, quality of the working environment, safety and security, or decreasing the carbon footprint of your business
                    </p>
                </div>
                <div class="table-detail">
                    <p>
                        Today Digital Transformation is one of the pillars of the Facility Management. Digital solutions offer a wide range of added value for facility managers. IoT with innovative technologies enables optimized, intelligent building management in many different areas. <br />
                        Discover how Facilities 4.0 provides you with tools and services to ensure and optimize the proper functioning, integrity, security or energy consumption of your environments, buildings, infrastructures, and real estate.<br />
                        Imagine solutions to monitor the life and use of your buildings in real-time, while also optimizing the management costs in order to increase your EBITDA. What if you could act now rather than react? 
                    </p>
                </div>
               <div class="table-speaker">
                    <p class="table-date">March 22</p>
                    <p class="table-time">15h30-16h30 CET</p>
                    <div class="table-btn">
                        <div class="btn" @click="goRT()">Participate in this roundtable</div>
                    </div>
                </div>
            </div>
            <div class="row-sep"></div>

            <!-- ROW 5 -->
            <div class="row">
                <div class="table-theme">
                    <div class="table-number">2</div>
                    <h2>production</h2>
                    <h3>MONITORING,<br />EXECUTION<br />& SUPERVISION</h3>
                </div>
                <div class="table-title">
                    <p>
                        <span>Industry 4.0 </span>
                        Integrating MES functions with an IIoT platform can bring new opportunities for manufacturers 
                    </p>
                </div>
                <div class="table-detail">
                    <p>
                        When deploying a solution to manage your production environment and infrastructure, diverse criteria need to be analyzed to make informed choices.<br />
                        Using the right platform can open fields of possibilities as well as the flexibility to deal with new opportunities, complementary use cases, and future changes.<br />
                        What if using an IIoT platform was the best option for either building a new optimal MES solution or for complementing your existing MES deployment?
                    </p>
                </div>
               <div class="table-speaker">
                    <p class="table-date">March 23</p>
                    <p class="table-time">14h00-15h00 CET</p>
                    <div class="table-btn">
                        <div class="btn" @click="goRT()">Participate in this roundtable</div>
                    </div>
                </div>
            </div>
            <div class="row-sep"></div>

            <!-- ROW 6 -->
            <div class="row">
                <div class="table-theme">
                    <div class="table-number">9</div>
                    <h2>LEGACY REPLACEMENT</h2>
                    <h3>GIVE A NEW LIFE <br />OF LEGACY DATA</h3>
                </div>
                <div class="table-title">
                    <p>
                        <span>Legacy Replacement</span>
                        Manage the obsolescence of your legacy engineering (PLM) systems
                    </p>
                </div>
                <div class="table-detail">
                    <p>
                        On average, 31% of an organisation’s technology is made up of legacy systems. Maintaining those systems can be a costly burden, with an average of 60-80% of IT budgets allocated to keeping them running. And half of senior IT leaders state that legacy systems are the main barrier to digital transformation, blocking their organisation’s progress. <br />
                        What are the challenges and potential paths to tackle the obsolescence of your engineering/PLM systems, and how modern Solution can help you secure your legacy product data?
                    </p>
                </div>
               <div class="table-speaker">
                    <p class="table-date">March 23</p>
                    <p class="table-time">15h30-16h30 CET</p>
                    <div class="table-btn">
                        <div class="btn" @click="goRT()">Participate in this roundtable</div>
                    </div>
                </div>
            </div>
            <div class="row-sep"></div>

            <!-- ROW 7 -->
            <div class="row">
                <div class="table-theme">
                    <div class="table-number">3</div>
                    <h2>MAINTENANCE</h2>
                    <h3>PREVENTIVE<br />& PREDICTIVE</h3>
                </div>
                <div class="table-title">
                    <p>
                        <span>Maintenance 4.0 </span>
                        How to optimize your maintenance solutions with an IIoT platform approach, while maintaining scalability to meet tomorrow’s challenges and benefits
                    </p>
                </div>
                <div class="table-detail">
                    <p>
                        The implementation of Industry 4.0 technology bricks leveraging IoT and AI facilitates the convergence of Information Technologies solutions (IT) with Operational Technologies solutions (OT), and optimizes the usability of capital and technical assets. The aggregation and exploitation of collected data via  AI techniques help maintenance teams using diagnostic, predictive, prescriptive, and adaptive analytics to improve maintenance under changing environmental conditions and on aging equipment.<br />
                        How can all of this be structured in a manner leading to an efficient and flexible digital maintenance architecture?
                    </p>
                </div>
                <div class="table-speaker">
                    <p class="table-date">March 24</p>
                    <p class="table-time">11h00-12h00 CET</p>
                    <div class="table-btn">
                        <div class="btn" @click="goRT()">Participate in this roundtable</div>
                    </div>
                </div>
            </div>
            <div class="row-sep"></div>

            <!-- ROW 8 -->
            <div class="row">
                <div class="table-theme">
                    <div class="table-number">8</div>
                    <h2>DATA FABRIC</h2>
                    <h3>DATA ACCESS<br />& REPORTING</h3>
                </div>
                <div class="table-title">
                    <p>
                        <span>Data Fabric </span>
                        Improve your digital continuity without adding additional entropy or complexity
                    </p>
                </div>
                <div class="table-detail">
                    <p>
                        One generic problem with data in the enterprise is that, as more data is added, the harder it becomes to manage and discover insights intrinsic to the embedded relationships, especially when the data is spread across multiple systems. Normally, this obliges companies to deploy multiple applications and search through data sources one by one hoping to find relevant data.<br />
                        As a result, many enterprises are missing opportunities for improving profitability, accelerating innovation, and achieving their Return on IT Investment.<br />
                        What benefits can be gained from adopting different approaches like indexation, cataloging, visualization and aggregation with a 360-degree view for data?
                    </p>
                </div>
               <div class="table-speaker">
                    <p class="table-date">March 24</p>
                    <p class="table-time">14h00-15h00 CET</p>
                    <div class="table-btn">
                        <div class="btn" @click="goRT()">Participate in this roundtable</div>
                    </div>
                </div>
            </div>
            <div class="row-sep"></div>




            <!-- ROW 9 -->
            <div class="row">
                <div class="table-theme">
                    <div class="table-number">7</div>
                    <h2>IMMERSIVE RENDERING</h2>
                    <h3>
                        SUPPORT TO <br />
                        OPERATIONS & SKILLS
                    </h3>
                </div>
                <div class="table-title">
                    <p>
                        <span>Immersive Rendering </span>
                        Boosting operator effectiveness by leveraging XR experiences
                    </p>
                </div>
                <div class="table-detail">
                    <p>
                        VR and AR can bring new solutions to capture know-how, to aid in resolving issues encountered in the factory or in the field, and to train new employees and subcontractors.<br />
                        However, how do we get beyond the simple "wow" effect and deploy VR and AR solutions effectively for more cost-effective maintenance and better safety for operators?<br />
                        What are the relative strengths of VR and AR? Are they competitive or complementary?
                    </p>
                </div>
             <div class="table-speaker">
                    <p class="table-date">March 24</p>
                    <p class="table-time">15h30-16h30 CET</p>
                    <div class="table-btn">                        
                        <div class="btn" @click="goRT()">Participate in this roundtable</div>
                    </div>
                </div>
            </div>




            <div id="scroll"></div>
        </section>
        <section class="rt-form" id="rt">
            <h2 class="title-solo">Register Form to Participate to the Roundtables</h2>
            <RTForm></RTForm>
        </section>
    </div>
</template>

<script>
import RTForm from "./../components/RTForm.vue";

export default {
    name : "RoundTables",
    components : {
        RTForm
    },
    methods : {
        goRT() {
            const el = document.getElementById("scroll");
            el.scrollIntoView({behavior: "smooth", block: 'start'});
        }
    }
}
</script>

<style lang="scss" scoped>
  .section-title {
        margin-top:150px;
        padding:80px 0;
        background-image: url('./../assets/bg-home-participate.png');
        background-position: center right;
        background-repeat: no-repeat;
    }

    .title-container {
        width:$box;
        margin:0 auto;
        max-width: 90%;
        display: flex;
        align-items: center;
    }

    .col-img {
        display: flex;
        justify-content: center;
        align-items: center;
        width:50%;
    }

    .col-img img {
        max-width: 90%;
    }

    .col-title {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 50%;
    }

    .col-title h1 {
        color:$main-color;
        font-size:40px;
        font-weight:700;
        padding: 0;
        margin: 0 0 20px 0;
        text-align: center;
    }

    .col-title p {
        color:$text-color;
        text-align: center;
        font-size:25px;
        line-height: 1.5em;
        max-width: 600px;
        margin: 0;
    }



    .table-container {
        width:1400px;
        max-width: 98%;
        margin: 40px auto;
    }

    .row {
        display: flex;
        width:100%;
        margin:10px 0;
    }

    .table-theme, .table-title, .table-speaker  {
        width:calc(20% - 10px);
        margin:0 5px;
        border:1px solid $main-color;
        padding:20px;
        display: flex;
        align-items:center;
        justify-content: center;
        flex-direction: column;
    }

    .table-detail {
        width:calc(40% - 10px);
        margin:0 5px;
        border:1px solid $main-color;
        padding:20px;
    }

    .row p {
        text-align: justify;
        color:$text-color;
    }

    .row .table-title p {
        font-weight: 700;
    }

    .row .table-title p {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .row .table-title p span {
        text-align: center;
        margin-bottom: 20px;
    }

    .row .table-number {
        text-align: center;
        color:$text-color;
        font-size:30px;
        font-weight: 700;
        display:none;
    }

    .row h2 {
        text-align: center;
        color:$text-color;
        font-size:20px;
        font-weight: 700; 
        letter-spacing: 1.5px;
        margin:5px 0 20px 0;
        text-transform: uppercase;
    }

    .row h3 {
        text-align: center;
        color:$text-color;
        font-size:18px;
        font-weight: 400; 
        letter-spacing: 1.5px;
        margin:0;
        text-transform: uppercase;
    }

    .row-header .table-theme, .row-header  .table-title, .row-header  .table-detail, .row-header  .table-speaker {
        background:$main-color;
        color:#fff;
        font-size:20px;
        letter-spacing: 1.5px;
        font-weight: 700;
        text-align: center;
        height:50px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
    }

    .row-sep {
        display: none;
    }

    .table-btn a, .table-btn .btn {
        background:$main-color;
        color:#fff;
        border:1px solid $main-color;
        padding:10px 20px;
        border-radius:10px;
        text-decoration: none;
        transition:0.5s;
        display: block;
        text-align: center;
        cursor: pointer;
    }

    .table-btn a:hover, .table-btn .btn:hover {
        background: #fff;
        color:$main-color;
    }


    .table-date {
        font-weight:700;
        font-size:22px;
        text-align: center;
        margin:0 0 20px 0;
    }

    .table-time {
        font-weight:700;
        font-size: 22px;
        text-align: center;
        margin:0 0 20px 0;
    }

    .rt-form {
        background-image: url("./../assets/bg-contact.jpg");
        background-position: center;
        background-size: cover;
        padding:80px 0;
        margin-top:80px;
    }

    @media screen and (max-width:768px) {

        .title-container img {
            display: none;
        }

        .col-title h1 {
            font-size:30px
        }   
        
        .col-title p {
            font-size:20px;
        }

        .title-container {
            flex-direction: column;
        }

        .col-img, .col-title {
            width:96%;
        }

        .section-title {
            padding:40px 0;
        }

        .row {
            flex-direction: column;
            margin:40px 10px;
        }

        .row .table-theme, .row .table-title, .row .table-detail, .row .table-speaker {
            width:calc(100% - 40px);
            margin:5px 0;
        }

        .row .table-title p {
            text-align: center;
        }

        .row-header  {
            display:none;
        }

        .row-sep {
            display: flex;
            justify-content: center;
            height:10px;
            width:10px;
            border-radius: 50%;
            background: $main-color;
        }

        .table-container {
            display: flex;
            flex-direction:column;
            align-items: center;
        }
    }
</style>